export const CHUNK_SIZE = 2 * 1024 * 1024;
export const MAX_TOS = 5;
export const GIGABYTES = 1024.0 * 1024.0 * 1024;

export function CreateGuid() {
  // Thanks to : https://stackoverflow.com/a/2117523/709884
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function delay(time) {
  return new Promise(resolve => setTimeout(() => resolve(), time));
}
